var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hero is-fullheight", attrs: { id: "app" } },
    [
      _c("LanguageSelector", { staticClass: "centered mb-2" }),
      _c("div", { staticClass: "hero-body" }, [
        _c("div", { staticClass: "container has-text-centered" }, [
          _c("div", { staticClass: "columns is-centered" }, [
            _c(
              "div",
              { staticClass: "column is-7" },
              [_c("alternatives"), _c("summary-step")],
              1
            ),
          ]),
        ]),
      ]),
      false
        ? _c(
            "svg",
            {
              staticClass: "bg-svg",
              attrs: {
                role: "presentation",
                preserveAspectRatio: "none",
                viewBox: "0 0 100 100",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "#FAFAFA",
                  d: "M0 100V0l100 100z",
                  "fill-rule": "evenodd",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }