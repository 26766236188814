/* eslint-disable */

import Vue from 'vue';
import Vuex from 'vuex';

import i18n from '@/languages/i18n';
import GetProducts from '../products';

Vue.use(Vuex);

const language = localStorage.getItem('language') || 'sv-SE';
i18n.locale = language;

const store = new Vuex.Store({
  modules: {},
  state: {
    steps: ['alternatives', 'summaryStep'],
    products: GetProducts(),
    showProductBreadcrumb: true,
    activeStep: 0,
    selections: {
      fillingMaterialId: { value: null, default: null, name: 'FyllnadsmaterialId' },
      fillingMaterialColor: { value: null, default: null, name: 'Fyllnadsmaterial Färg' },
      fillingMaterialSize: { value: null, default: null, name: 'Säckstorlek', sortingorder: 99 },
      stoneLength: { value: 210, default: 210, min: 50, max: 1200, name: 'Stenlängd mm', sortingorder: 1 },
      stoneWidth: { value: 140, default: 140, min: 50, max: 700, name: 'Stenbredd mm', sortingorder: 2 },
      jointThickness: { value: 60, default: 60, name: 'Fogdjup mm', sortingorder: 3 },
      jointWidth: { value: 5, default: 5, name: 'Fogbredd mm', sortingorder: 4 },
      pavingArea: { value: 10, default: 10, name: 'Yta m2', sortingorder: 5 },
    },
    frameData: {
      loading: true,
    },
  },
  getters: {
    getSteps(state) {
      return state.steps;
    },
    getActiveStep(state) {
      return state.activeStep;
    },
    getProducts(state) {
      return state.products;
    },
    getSelections(state) {
      return state.selections;
    },
    getSelectedValue: (state) => (valueName) => {
      return [null, undefined].includes(state.selections[valueName].value) ? state.selections[valueName].default : state.selections[valueName].value;
    },
    getSelectedMaterialObj(state, getters) {
      let materialId = getters.getSelectedValue('fillingMaterialId');
      if (!materialId) return {};
      let products = getters.getProducts;
      let product = products.find((p) => p.id === materialId);
      let variant = product.variants.find((v) => {
        return v.color == state.selections.fillingMaterialColor.value && v.weightKg == state.selections.fillingMaterialSize.value;
      });
      if (!variant) return {};
      let fullProduct = { ...product, ...variant };
      delete fullProduct.variants;
      return fullProduct;
    },
    getAmountJointFillingKgPerM2(state, getters) {
      // Hämta det valda materialobjektet
      let material = getters.getSelectedMaterialObj;

      // Hämta värdena för stenbredd, fogbredd, fogtjocklek och stenlängd
      let stoneWidth = getters.getSelectedValue('stoneWidth');
      let jointWidth = getters.getSelectedValue('jointWidth');
      let jointThickness = getters.getSelectedValue('jointThickness');
      let stoneLength = getters.getSelectedValue('stoneLength');

      // Deklarera variabler för beräkningarna
      let stenar_per_m2 = 0;
      let fogvolym_per_sten = 0;
      let total_fogvolym_per_m2 = 0;
      let total_vikt_per_m2 = 0;

      // Om material inte är valt, returnera 0
      if (!material) return 0;

      // Beräkna antalet stenar per kvadratmeter
      // Förutsätter att måtten är i millimeter.
      // 1000 mm är omvandlingsfaktorn från mm till meter för att beräkna per kvadratmeter.
      stenar_per_m2 = (1000 / (stoneWidth + jointWidth)) * (1000 / (stoneLength + jointWidth));

      // Beräkna fogvolymen per sten
      // Fogvolymen är skillnaden mellan volymen av sten + fog och volymen av bara stenen.
      fogvolym_per_sten = ((stoneWidth + jointWidth) * (stoneLength + jointWidth) * jointThickness) - (stoneWidth * stoneLength * jointThickness);

      // Beräkna den totala fogvolymen per kvadratmeter
      // Multiplicera antalet stenar per kvadratmeter med fogvolymen per sten.
      total_fogvolym_per_m2 = stenar_per_m2 * fogvolym_per_sten;

      // Beräkna den totala vikten per kvadratmeter
      // Förutsätter att materialets densitet ges i kg/m³.
      // Omvandlar densiteten från kg/m³ till g/cm³ genom att dela med 1000.
      // Resultatet är i kg.
      total_vikt_per_m2 = (total_fogvolym_per_m2 * (material.density / 1000)) / 1000;

      // Returnera det beräknade värdet
      return parseFloat(total_vikt_per_m2);
    },
    getAmountJointFillingKg(state, getters) {
      let amountJointFillingKgPerM2 = getters.getAmountJointFillingKgPerM2;
      let pavingArea = getters.getSelectedValue('pavingArea');
      return parseFloat(amountJointFillingKgPerM2 * pavingArea / 1000).toFixed(2);
    },
    locale() {
      return i18n.locale || 'sv-SE';
    },
  },
  mutations: {
    setActiveStep(state, payload) {
      state.activeStep = payload;
    },
    setValue(state, payload) {
      state.selections[payload.name].value = payload.value;
    },
    resetValue(state, payload) {
      state.selections[payload].value = state.selections[payload].default;
    },
    setFrameData(state, data) {
      state.frameData = data;
    },
    updateLanguage(state, language) {
      localStorage.setItem('language', language);
      i18n.locale = language;
      state.products = GetProducts();
    },
  },
  actions: {
    previousStep({ state, commit, dispatch, rootState }) {
      commit('setActiveStep', state.activeStep - 1);
      // dispatch('updateProducts')
    },
    nextStep({ state, commit, dispatch, rootState }) {
      commit('setActiveStep', state.activeStep + 1);
      // dispatch('updateProducts')
    },
    goToStep({ state, commit, dispatch, rootState }, step) {
      switch (step) {
        case 0:
          commit('resetValue', 'fillingMaterialId');
          commit('resetValue', 'fillingMaterialColor');
          commit('resetValue', 'fillingMaterialSize');
          commit('resetValue', 'stoneLength');
          commit('resetValue', 'stoneWidth');
          commit('resetValue', 'jointThickness');
          commit('resetValue', 'jointWidth');
          commit('resetValue', 'pavingArea');
      }
      commit('setActiveStep', step);
    },
    updateValue({ commit }, payload) {
      commit('setValue', payload);
    },
    updateFrameData(context, data) {
      context.commit('setFrameData', data);
    },
    setLanguage({ commit }, language) {
      commit('updateLanguage', language);
    },
  },
});
export default store;
