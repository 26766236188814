import './polyfills';

import Vue from 'vue';

import App from './App.vue';
import Buefy from 'buefy';
import store from './store/store';
import i18n from './languages/i18n';

import './directives.js';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import '/node_modules/flag-icons/css/flag-icons.min.css';

Vue.use(Buefy);

Vue.config.productionTip = process.env.NODE_ENV === 'development';

new Vue({
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
