var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "non-printable" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("alternatvies.description")) + "\n  "),
      ]),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "rg-from" } }, [
            _vm._v(_vm._s(_vm.$t("alternatives.stone_length"))),
          ]),
          _c("NumberField", {
            staticClass: "is-short",
            attrs: { min: 10, max: 1000, step: 5 },
            model: {
              value: _vm.stoneLength,
              callback: function ($$v) {
                _vm.stoneLength = $$v
              },
              expression: "stoneLength",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "rg-from" } }, [
            _vm._v(_vm._s(_vm.$t("alternatives.stone_width"))),
          ]),
          _c("NumberField", {
            staticClass: "is-short",
            attrs: { min: 10, max: 1000, step: 5 },
            model: {
              value: _vm.stoneWidth,
              callback: function ($$v) {
                _vm.stoneWidth = $$v
              },
              expression: "stoneWidth",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "rg-from" } }, [
            _vm._v(_vm._s(_vm.$t("alternatives.joint_depth"))),
          ]),
          _c("NumberField", {
            staticClass: "is-short",
            attrs: { min: 30, max: 200, step: 5 },
            model: {
              value: _vm.jointThickness,
              callback: function ($$v) {
                _vm.jointThickness = $$v
              },
              expression: "jointThickness",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "rg-from" } }, [
            _vm._v(_vm._s(_vm.$t("alternatives.joint_width"))),
          ]),
          _c("NumberField", {
            staticClass: "is-short",
            attrs: { min: 1, max: 50, step: 1 },
            model: {
              value: _vm.jointWidth,
              callback: function ($$v) {
                _vm.jointWidth = $$v
              },
              expression: "jointWidth",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "rg-from" } }, [
            _vm._v(_vm._s(_vm.$t("alternatives.paving_area"))),
          ]),
          _c("NumberField", {
            staticClass: "is-short",
            attrs: { min: 0, max: 9999, step: 5 },
            model: {
              value: _vm.pavingArea,
              callback: function ($$v) {
                _vm.pavingArea = $$v
              },
              expression: "pavingArea",
            },
          }),
        ],
        1
      ),
      _vm.availableProducts.length > 0
        ? _c("radioButtonGroup", {
            attrs: {
              label: _vm.$t("alternatives.filling_material"),
              options: _vm.availableProducts,
              nativeText: "name",
            },
            model: {
              value: _vm.selectedMaterialId,
              callback: function ($$v) {
                _vm.selectedMaterialId = $$v
              },
              expression: "selectedMaterialId",
            },
          })
        : _c("div", [
            _vm._v(
              _vm._s(
                _vm.$t("alternatives.error.no_products_matching_selections")
              )
            ),
          ]),
      _vm.availableColors.length > 1
        ? _c("radioButtonGroup", {
            attrs: {
              label: _vm.$t("alternatives.filling_color"),
              options: _vm.availableColors,
            },
            model: {
              value: _vm.fillingMaterialColor,
              callback: function ($$v) {
                _vm.fillingMaterialColor = $$v
              },
              expression: "fillingMaterialColor",
            },
          })
        : _vm._e(),
      _vm.availableSizes.length > 1
        ? _c("radioButtonGroup", {
            attrs: {
              label: _vm.$t("alternatives.sack_size"),
              options: _vm.availableSizes,
            },
            model: {
              value: _vm.fillingMaterialSize,
              callback: function ($$v) {
                _vm.fillingMaterialSize = $$v
              },
              expression: "fillingMaterialSize",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }