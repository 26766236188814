var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tableRows.length
    ? _c("div", { staticClass: "mt-6" }, [
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "modal is-active",
                class: { "is-hidden": !_vm.isCardModalActive },
              },
              [
                _c("div", {
                  staticClass: "modal-background",
                  on: {
                    click: function ($event) {
                      _vm.isCardModalActive = false
                    },
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "animation-content modal-content",
                    staticStyle: { "max-width": "640px" },
                  },
                  [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", [
                          _c(
                            "h2",
                            { staticClass: "title is-size-4 is-uppercase" },
                            [_vm._v(_vm._s(_vm.$t("summary.your_choices")))]
                          ),
                          _c(
                            "div",
                            {
                              ref: "choicesTable",
                              staticClass: "columns is-centered printable",
                              attrs: { id: "choicesTable" },
                            },
                            [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table is-narrow is-fullwidth-mobile dd-table",
                                },
                                [
                                  _vm._l(_vm.selections, function (selection) {
                                    return [
                                      _c("tr", { key: selection.name }, [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "has-text-weight-bold has-text-right",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(selection.name) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                        _c("td", [
                                          _vm._v(_vm._s(selection.value)),
                                        ]),
                                      ]),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _c("div", {
              ref: "choicesTablePrint",
              staticClass: "only-printable",
              attrs: { id: "choicesTablePrint" },
            }),
            _c(
              "b-modal",
              {
                attrs: {
                  active: _vm.emailSummarySent,
                  width: 640,
                  scroll: "keep",
                },
                on: {
                  "update:active": function ($event) {
                    _vm.emailSummarySent = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-content" }, [
                    _c("p", { staticClass: "is-size-6 is-size-5-tablet" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("summary.email_sent")) +
                          ":\n            "
                      ),
                      _c("b", { staticClass: "has-text-weight-bold" }, [
                        _vm._v(_vm._s(_vm.emailSummaryReceiver)),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "table",
          {
            ref: "summaryTable",
            staticClass:
              "table margin-xxxlarge-vertical is-fullwidth is-size-7",
            attrs: { id: "summaryTable" },
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "is-uppercase has-text-left" }, [
                  _vm._v(_vm._s(_vm.$t("generic.product"))),
                ]),
                _c("th", { staticClass: "is-uppercase has-text-right" }, [
                  _vm._v(_vm._s(_vm.$t("generic.amount"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.sortedTableRows, function (tableRow) {
                return _c("tr", { key: tableRow.artNbr }, [
                  _c("td", { staticClass: "has-text-left is-size-6" }, [
                    _vm._v(
                      "\n          " + _vm._s(tableRow.product) + "\n          "
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "is-block has-text-weight-normal has-text-light is-size-8",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("generic.artnr")) +
                            _vm._s(tableRow.artNbr)
                        ),
                      ]
                    ),
                  ]),
                  _c("td", { staticClass: "has-text-right is-size-6" }, [
                    _vm._v(
                      _vm._s(tableRow.qty) +
                        " " +
                        _vm._s(tableRow.qty != "-" ? tableRow.unit : "")
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "non-printable disclaimer space-under is-size-7" },
          [
            _c("p", [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("summary.disclaimer")) + "\n      "
              ),
              _c(
                "a",
                {
                  staticClass: "non-printable",
                  attrs: { href: _vm.articleInfoUrl, target: "_blank" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("summary.disclaimer_link")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            ref: "disclaimer",
            staticClass: "only-printable disclaimer space-under is-size-7",
          },
          [
            _c("p", [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("summary.disclaimer")) + "\n    "
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "non-printable" }, [
          _c("nav", { staticClass: "level center" }, [
            _c("div", { staticClass: "level-right" }, [
              _c("div", { staticClass: "level-item" }, [
                _c(
                  "form",
                  {
                    ref: "pdfSummaryForm",
                    attrs: {
                      method: "POST",
                      target: "_blank",
                      action: _vm.pdfSummaryAction,
                    },
                  },
                  [
                    _c("input", {
                      attrs: { type: "hidden", name: "name" },
                      domProps: { value: _vm.pdfSummaryName },
                    }),
                    _c("input", {
                      attrs: { type: "hidden", name: "body" },
                      domProps: { value: _vm.pdfSummaryBody },
                    }),
                    _c("input", {
                      attrs: { type: "hidden", name: "print" },
                      domProps: { value: _vm.pdfSummaryPrint ? "y" : "n" },
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "is-uppercase has-text-weight-bold",
                        attrs: { size: "is-small", type: "is-link" },
                        on: { click: _vm.pdfSummarySubmit },
                      },
                      [_vm._v(_vm._s(_vm.$t("summary.save_pdf")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "level-item" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "is-uppercase has-text-weight-bold",
                      attrs: { size: "is-small", type: "is-link" },
                      on: { click: _vm.printSummary },
                    },
                    [_vm._v(_vm._s(_vm.$t("summary.print")))]
                  ),
                ],
                1
              ),
              _vm.showAddToCartBtn
                ? _c(
                    "div",
                    { staticClass: "level-item" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "button-loader",
                              rawName: "v-button-loader:dot-collision",
                              value: _vm.$store.state.frameData.loading,
                              expression: "$store.state.frameData.loading",
                              arg: "dot-collision",
                            },
                          ],
                          staticClass: "is-uppercase has-text-weight-bold",
                          attrs: {
                            size: "is-small",
                            type: "is-link",
                            disabled: _vm.$store.state.frameData.loading,
                          },
                          on: { click: _vm.addToCart },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("summary.add_to_cart")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }