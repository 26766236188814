import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const locale = 'sv-SE';

export default new VueI18n({
  locale,
  messages: {
    'en-US': require('./en_US.json'),
    'sv-SE': require('./sv_SE.json'),
    'fi-FI': require('./fi_FI.json'),
    'nb-NO': require('./nb_NO.json'),
    'de-DE': require('./de_DE.json'),
  },
  silentTranslationWarn: true,
});
