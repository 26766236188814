<template>
  <div>
    <!-- <title-component>Fogmaterialsberäkning</title-component> -->
    <p class="non-printable">
      {{ $t('alternatvies.description') }}
    </p>
    <div class="form-group">
      <label for="rg-from">{{ $t('alternatives.stone_length') }}</label>
      <NumberField
        class="is-short"
        :min="10"
        :max="1000"
        :step="5"
        v-model="stoneLength"
      />
    </div>

    <div class="form-group">
      <label for="rg-from">{{ $t('alternatives.stone_width') }}</label>
      <NumberField
        class="is-short"
        :min="10"
        :max="1000"
        :step="5"
        v-model="stoneWidth"
      />
    </div>

    <div class="form-group">
      <label for="rg-from">{{ $t('alternatives.joint_depth') }}</label>
      <NumberField
        class="is-short"
        :min="30"
        :max="200"
        :step="5"
        v-model="jointThickness"
      />
    </div>

    <div class="form-group">
      <label for="rg-from">{{ $t('alternatives.joint_width') }}</label>
      <NumberField
        class="is-short"
        :min="1"
        :max="50"
        :step="1"
        v-model="jointWidth"
      />
    </div>

    <div class="form-group">
      <label for="rg-from">{{ $t('alternatives.paving_area') }}</label>
      <NumberField
        class="is-short"
        :min="0"
        :max="9999"
        :step="5"
        v-model="pavingArea"
      />
    </div>

    <radioButtonGroup
      v-if="availableProducts.length > 0"
      :label="$t('alternatives.filling_material')"
      :options="availableProducts"
      nativeText="name"
      v-model="selectedMaterialId"
    />
    <div v-else>{{ $t('alternatives.error.no_products_matching_selections') }}</div>

    <radioButtonGroup
      v-if="availableColors.length > 1"
      :label="$t('alternatives.filling_color')"
      :options="availableColors"
      v-model="fillingMaterialColor"
    />

    <radioButtonGroup
      v-if="availableSizes.length > 1"
      :label="$t('alternatives.sack_size')"
      :options="availableSizes"
      v-model="fillingMaterialSize"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "JointMaterial",
  components: {
    // titleComponent: () => import("@/components/titleComponent"),
    NumberField: () => import("@/components/NumberField"),
    radioButtonGroup: () => import("../radioButtonGroup"),
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      selections: (state) => state.selections,
    }),
    getSelections() {
      return this.$store.getters.getSelections;
    },
    products() {
      return this.$store.getters.getProducts;
    },
    availableProducts() {
      let products = this.products.filter((product) => {
        return (
          product.minWidthJoint <= this.jointWidth &&
          product.maxWidthJoint >= this.jointWidth &&
          product.minHeightJoint <= this.jointThickness &&
          product.maxHeightJoint >= this.jointThickness
        );
      });

      if (
        products.filter((product) => product.id === this.selectedMaterialId)
          .length === 0
      ) {
        this.resetSelectedProduct();
      }

      return products;
      // .map(product => product.name)
    },
    selectedProduct() {
      if (!this.selectedMaterialId) return {};

      return this.products.filter(
        (product) => product.id === this.selectedMaterialId
      )[0];
    },
    availableColors() {
      if (this.selectedProduct.variants === undefined) return [];
      let colors = this.selectedProduct.variants.map(
        (variant) => variant.color
      );
      let uniqecolors = [...new Set(colors)];

      let colorArr = uniqecolors.map((color) => {
        return { id: color, value: color };
      });

      return colorArr;
    },
    availableSizes() {
      if (this.selectedProduct.variants === undefined) return [];
      let sizes = this.selectedProduct.variants.map(
        (variant) => variant.weightKg
      );
      let uniqeSizes = [...new Set(sizes)];

      let sizesArr = uniqeSizes.map((size) => {
        return { id: size, value: size, text: size + " kg" };
      });

      return sizesArr;
    },
    stoneLength: {
      get() {
        return this.$store.getters.getSelectedValue("stoneLength");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "stoneLength",
          value: value,
        });
      },
    },
    stoneWidth: {
      get() {
        return this.$store.getters.getSelectedValue("stoneWidth");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "stoneWidth",
          value: value,
        });
      },
    },
    jointThickness: {
      get() {
        return this.$store.getters.getSelectedValue("jointThickness");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "jointThickness",
          value: value,
        });
      },
    },
    jointWidth: {
      get() {
        return this.$store.getters.getSelectedValue("jointWidth");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "jointWidth",
          value: value,
        });
      },
    },
    pavingArea: {
      get() {
        return this.$store.getters.getSelectedValue("pavingArea");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "pavingArea",
          value: value,
        });
      },
    },
    selectedMaterialId: {
      get() {
        return this.$store.getters.getSelectedValue("fillingMaterialId");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "fillingMaterialId",
          value: value,
        });
      },
    },
    fillingMaterialColor: {
      get() {
        return this.$store.getters.getSelectedValue("fillingMaterialColor");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "fillingMaterialColor",
          value: value,
        });
      },
    },
    fillingMaterialSize: {
      get() {
        return this.$store.getters.getSelectedValue("fillingMaterialSize");
      },
      set(value) {
        this.$store.dispatch("updateValue", {
          name: "fillingMaterialSize",
          value: value,
        });
      },
    },
  },
  methods: {
    resetSelectedProduct() {
      this.selectedMaterialId = null;
    },
    selectSingle(arr) {
      if (arr.length === 1) {
        return arr[0].value;
      } else {
        return null;
      }
    },
    constrainValue(selections = this.getSelections) {
      for (let key in selections) {
        let constrainedValue =
          selections[key].min !== undefined && selections[key].max !== undefined
            ? Math.min(
                selections[key].max,
                Math.max(selections[key].min, selections[key].value)
              )
            : null;
        if (
          [null, undefined, ""].includes(selections[key].value) ||
          constrainedValue !== null
        ) {
          this.$store.dispatch("updateValue", {
            name: key,
            value: constrainedValue || selections[key].default,
          });
        }
      }
    },
  },
  watch: {
    availableSizes(sizesArr) {
      this.fillingMaterialSize = this.selectSingle(sizesArr);
    },
    availableColors(colorArr) {
      this.fillingMaterialColor = this.selectSingle(colorArr);
    },
  },
};
</script>

<style scoped>
label {
  display: inline-block;
  width: 150px;
  color: #464749;
  font-size: 1rem;
  font-weight: 700;
  text-align: right;
  padding: 0 5px;
}
input,
select {
  border: 2px solid #d1d3d6;
  line-height: 1;
  border-radius: 0;
  background-color: white;
  color: #464749;
  outline: none;
  font-size: 1rem;
  padding: 7px 11px;
  width: 150px;
}
input .disabled {
  border: none;
}
.is-warning {
  color: red;
}
.form-group {
  margin: 15px;
}
.help-text {
  text-align: left;
}
.radiobtn label {
  background-color: blue;
}

.is-short {
  min-width: 6rem;
  width: 6rem;
}
</style>
