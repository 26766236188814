import Vue from 'vue'

Vue.directive('button-loader', {
    bind(el, binding) {
        const inner = el.innerHTML
        const type = binding.arg

        el.innerHTML = `
      <span id="button-loader-container">${inner}</span>
      <div id="button-loader-animation" class="is-hidden ${type}" style="position: absolute"></div>
    `
    },
    update(el, binding) {
        // Add check if should update

        const enabled = binding.value
        const container = el.querySelector('#button-loader-container')
        const anim = el.querySelector('#button-loader-animation')
        if (enabled) {
            container.classList.add('is-invisible')
            anim.classList.remove('is-hidden')
        } else {
            container.classList.remove('is-invisible')
            anim.classList.add('is-hidden')
        }
    }
})