<template>
  <div id="app" class="hero is-fullheight">
    <LanguageSelector class="centered mb-2" />
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered">
          <div class="column is-7">
            <alternatives></alternatives>
            <summary-step></summary-step>
          </div>
        </div>
      </div>
    </div>
    <svg v-if="false" role="presentation" class="bg-svg" preserveAspectRatio="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path fill="#FAFAFA" d="M0 100V0l100 100z" fill-rule="evenodd" />
    </svg>
  </div>
</template>

<script>
import alternatives from '@/components/steps/alternatives';
import summaryStep from '@/components/steps/summaryStep';
import LanguageSelector from '@/components/LanguageSelector';
import { mapGetters } from 'vuex';

export default {
  name: 'app',
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters(['locale']),
  },
  methods: {},
  created() {
    // this.$store.dispatch('refresh');
    window.addEventListener('message', (event) => {
      const data = event.data;
      if (typeof data !== 'object' || data === null) {
        return;
      }
      switch (data.type) {
        case 'bendersReady':
          window.parent.postMessage({ type: 'bendersInit' }, '*');
          break;
        case 'bendersInit':
          this.$store.dispatch('updateFrameData', data.data);
          break;
        case 'bendersLoading':
          this.$store.dispatch('updateFrameData', {
            ...this.$store.state.frameData,
            loading: data.data,
          });
          break;
      }
    });
  },
  components: {
    alternatives,
    summaryStep,
    LanguageSelector,
  },
};
</script>

<style lang="sass">
@import '~vue-slider-component/lib/theme/default.scss'
@import "@/assets/styles/style.sass"

.button.button
  height: auto
  border-radius: $control-radius !important
  line-height: 1
.input, .textarea, .select select
  border-width: 2px
  line-height: 1
  border-radius: $control-radius !important

.table
  td,th
    vertical-align: middle

.title
  margin-bottom: 2rem
  @media (min-width: 767px)
    margin-bottom: 4rem
  /*&::after
   content: ''
   display: block
   width: 0
   height: 0
   border-left: 8px solid transparent
   border-right: 8px solid transparent
   border-top: 10px solid $red
   margin: 10px auto 0 auto
   */

.option-list.option-list
  margin-bottom: 2rem
  @media (min-width: 767px)
    margin-bottom: 4rem

.bg-svg
  position: fixed
  top: 0
  left: 0
  z-index: -1
  width: 100vw
  height: 100vh

.vue-slider-rail
  background: $grey-light
  .vue-slider-process
    background: $grey-light
  .vue-slider-dot-handle
    box-shadow: none
    background: $red
    border-radius: 0
    &:hover
      cursor: pointer
  .vue-slider-dot-tooltip-top
    top: 0
  .vue-slider-dot-tooltip-inner
    border-color: transparent
    background: transparent
    border-radius: 0
    color: $dark
  &:hover
    cursor: pointer
.range-field
  label
    min-width: 13%
    text-align: left

// BK-43
.hero.is-fullheight
  min-height: unset !important
.hero-body
  padding-top: 0 !important
  align-items: start !important
  flex-grow: unset !important

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
  .hero-body
    display: block !important

.button-left,
.button-right
  color: #a6192e
  font-weight: bold
  position: relative

.button-left
  padding-right: 0

.button-right
  padding-left: 0

.button-left::after,
.button-right::after
  content: ""
  display: block
  width: 0
  height: 0
  position: absolute

.button-left::after
  border-top: 8px solid transparent
  border-bottom: 8px solid transparent
  border-right: 10px solid #a6192e
  left: 0

.button-right::after
  border-bottom: 8px solid transparent
  border-top: 8px solid transparent
  border-left: 10px solid #a6192e
  right: 0
</style>
