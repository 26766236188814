<template>
  <div class="language level-item top-navigation__item is-size-8 tracking-wide has-text-dark is-uppercase" data-has-children="true">
    <a @click="pickerVisible = !pickerVisible" href="#">Language ({{ label }})</a>
    <ul v-if="pickerVisible" @mouseleave="pickerVisible = false" @click="pickerVisible = false">
      <li v-for="language in languages" :key="language.key" class="two">
        <span :class="`fi fi-${language.img.flag}`"></span>
        <ul>
          <li v-for="option in language.options" :key="option.label">
            <a @click="setLanguage(option.value)" href="#">{{ option.label }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data() {
    return {
      pickerVisible: false,
      languages: [
        {
          key: 'sv-SE',
          label: 'Svenska',
          img: {
            flag: 'se',
            alt: 'Svenska',
          },
          options: [
            {
              label: 'Svenska',
              value: 'sv-SE',
            },
          ],
        },
        {
          key: 'en-US',
          label: 'English',
          img: {
            flag: 'us',
            alt: 'English',
          },
          options: [
            {
              label: 'English',
              value: 'en-US',
            },
          ],
        },
        {
          key: 'de-DE',
          label: 'Deutsch',
          img: {
            flag: 'de',
            alt: 'Deutsch',
          },
          options: [
            {
              label: 'Deutsch',
              value: 'de-DE',
            },
          ],
        },
        {
          key: 'fi-FI',
          label: 'Suomi',
          img: {
            flag: 'fi',
            alt: 'Suomi',
          },
          options: [
            {
              label: 'Suomi',
              value: 'fi-FI',
            },
          ],
        },
        {
          key: 'nb-NO',
          label: 'Norsk',
          img: {
            flag: 'no',
            alt: 'Norsk',
          },
          options: [
            {
              label: 'Norsk',
              value: 'nb-NO',
            },
          ],
        },
      ],
    };
  },
  methods: {
    setLanguage(language) {
      this.$store.dispatch('setLanguage', language);
    },
  },
  computed: {
    label() {
      for (let language of this.languages) {
        for (let option of language.options) {
          if (option.value === this.$i18n.locale) {
            return option.label;
          }
        }
      }

      return 'Unknown';
    },
  },
};
</script>

<style lang="sass" scoped>
.help-text
  text-align: center

.language
  position: relative
  // margin: 1.2rem 0

  &>a:after
    display: inline-block
    width: 0
    height: 0
    margin-left: 4px
    border: 4px solid transparent
    border-top-color: #58595b
    content: ""
    vertical-align: middle

  &>ul
    position: absolute
    top: 100%
    left: -0.5rem
    // right: 30%
    z-index: 99
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    width: 360px
    margin: 0
    padding: 10px
    background: #f8f8f8
    text-align: left

  & li
    display: block
    position: relative
    float: left
    clear: both
    width: 100%
    margin: 0
    padding: 12px 0 6px
    border-bottom: 1px solid #dadada

.language li.two li:after,
.language li.two:after
  width: 8px
  height: 1px
  display: block
  background: #ddd
  content: ""
  position: absolute

.language li ul
  float: left
  margin: 0 0 0 30px

.language li a
  float: left
  padding: 0 3px 6px 15px
  font-weight: 400
  line-height: 20px
  text-decoration: none

.language li:last-child
  border-bottom: none

.language li .fi
  float: left
  padding-top: 3px

.language li.two li
  padding: 0
  border: none

  &:after
    top: 9px

.language li.two:after
  top: 50%
  bottom: 50%
  left: 22px
  margin-top: -1px

.language li.two .fi
  position: absolute
  top: 50%
  left: 0
  margin: -7px 0 0
  padding-top: 0

.language li.two ul:after
  display: block
  position: absolute
  top: 22px
  bottom: 22px
  width: 1px
  background: #ddd
  content: ""

.language
  text-transform: uppercase

  & a
    color: #58595b

  &>ul
    overflow: hidden
    margin: 0

.language.centered ul
  left: initial
</style>
