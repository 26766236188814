import i18n from '@/languages/i18n';

export default function () {
  return [
    {
      id: 1,
      name: i18n.t('products.fogsand02mm'),
      type: 'Fogsand 0-2mm',
      unit: 'säck',
      density: 1600,
      imageUrl: 'rooftiles',
      minWidthJoint: 2,
      maxWidthJoint: 20,
      minHeightJoint: 30,
      maxHeightJoint: 200,
      variants: [
        { artNumber: '2984101-D', name: i18n.t('products.2984101-D'), color: i18n.t('products.color.graphite'), weightKg: 15 },
        { artNumber: '2984107-D', name: i18n.t('products.2984107-D'), color: i18n.t('products.color.gray'), weightKg: 15 },
        { artNumber: '2984110-D', name: i18n.t('products.2984110-D'), color: i18n.t('products.color.sand'), weightKg: 15 },
      ],
    },
    {
      id: 2,
      name: i18n.t('products.fogsand_dansand_ograshammande'),
      type: 'Fogsand Dansand ogräshämmande',
      unit: 'säck',
      density: 1500,
      imageUrl: 'rooftiles',
      minWidthJoint: 1,
      maxWidthJoint: 5,
      minHeightJoint: 40,
      maxHeightJoint: 150,
      variants: [
        { artNumber: '2983501-E', name: i18n.t('products.2983501-E'), color: i18n.t('products.color.graphite'), weightKg: 20 },
        { artNumber: '2983507-E', name: i18n.t('products.2983507-E'), color: i18n.t('products.color.gray'), weightKg: 20 },
        { artNumber: '2983565-E', name: i18n.t('products.2983565-E'), color: i18n.t('products.color.sand'), weightKg: 20 },
      ],
    },
    {
      id: 3,
      name: i18n.t('products.fogsand_lithomex_easy_flexifog'),
      type: 'Fogsand Lithomex Easy flexifog',
      unit: 'säck',
      density: 1500,
      imageUrl: 'rooftiles',
      minWidthJoint: 1,
      maxWidthJoint: 10,
      minHeightJoint: 30,
      maxHeightJoint: 100,
      variants: [{ artNumber: '2984617-E', name: i18n.t('products.2984617-E'), color: i18n.t('products.color.gray'), weightKg: 20 }],
    },
    {
      id: 4,
      name: i18n.t('products.fogsand_lithomex_quicksand'),
      type: 'Lithomex Quicksand fogmassa',
      unit: 'st',
      density: 1500,
      imageUrl: 'rooftiles',
      minWidthJoint: 3,
      maxWidthJoint: 20,
      minHeightJoint: 30,
      maxHeightJoint: 100,
      variants: [
        { artNumber: '2984901', name: i18n.t('products.2984901'), color: i18n.t('products.color.graphite'), weightKg: 15 },
        { artNumber: '2984907', name: i18n.t('products.2984907'), color: i18n.t('products.color.gray'), weightKg: 15 },
      ],
    },
    {
      id: 5,
      name: i18n.t('products.fogsand_lithomex_heavy_L15'),
      type: 'Lithomex Heavy L15 hårdfog',
      unit: 'set',
      density: 1700,
      imageUrl: 'rooftiles',
      minWidthJoint: 3,
      maxWidthJoint: 50,
      minHeightJoint: 30,
      maxHeightJoint: 100,
      variants: [
        { artNumber: '2983407-A', name: i18n.t('products.2983407-A'), color: i18n.t('products.color.gray'), weightKg: 27.5 },
      ],
    },
    {
      id: 6,
      name: i18n.t('products.fogsand_lithomex_heavy_L20'),
      type: 'Lithomex Heavy L20 hårdfog',
      unit: 'set',
      density: 1700,
      imageUrl: 'rooftiles',
      minWidthJoint: 5,
      maxWidthJoint: 50,
      minHeightJoint: 30,
      maxHeightJoint: 100,
      variants: [
        // { artNumber: '2983607-A', name: i18n.t('products.2983607-A'), color: i18n.t('products.color.gray'), weightKg: 27.5 }
        { artNumber: '2983617-A', name: i18n.t('products.2983617-A'), color: i18n.t('products.color.gray'), weightKg: 27.5 },
      ],
    },
    {
      id: 7,
      name: i18n.t('products.fogsand_lithomex_heavy_L50'),
      type: 'Lithomex Heavy L50 hårdfog',
      unit: 'set',
      density: 1800,
      imageUrl: 'rooftiles',
      minWidthJoint: 8,
      maxWidthJoint: 50,
      minHeightJoint: 40,
      maxHeightJoint: 100,
      variants: [
        // { artNumber: '2983707-A', name: i18n.t('products.2983707-A'), color: i18n.t('products.color.gray'), weightKg: 28 }
        { artNumber: '2983717-A', name: i18n.t('products.2983717-A'), color: i18n.t('products.color.gray'), weightKg: 28 },
      ],
    },
    {
      id: 8,
      name: i18n.t('products.stoneflour04mm'),
      type: 'Stenmjöl 0-4mm',
      unit: 'säck',
      density: 1600,
      imageUrl: 'rooftiles',
      minWidthJoint: 5,
      maxWidthJoint: 50,
      minHeightJoint: 30,
      maxHeightJoint: 150,
      variants: [
        { artNumber: '2984210-D', name: i18n.t('products.2984210-D'), color: i18n.t('products.color.gray'), weightKg: 15 },
        { artNumber: '2984210-B', name: i18n.t('products.2984210-B'), color: i18n.t('products.color.gray'), weightKg: 250 },
        { artNumber: '2984210-C', name: i18n.t('products.2984210-C'), color: i18n.t('products.color.gray'), weightKg: 800 },
      ],
    },
    {
      id: 9,
      name: i18n.t('products.stonechips25mm'),
      type: 'Stenflis 2-5mm',
      unit: 'säck',
      density: 1500,
      imageUrl: 'rooftiles',
      minWidthJoint: 5,
      maxWidthJoint: 50,
      minHeightJoint: 60,
      maxHeightJoint: 150,
      variants: [
        { artNumber: '2983920-D', name: i18n.t('products.2983920-D'), color: i18n.t('products.color.gray'), weightKg: 15 },
        { artNumber: '2983920-B', name: i18n.t('products.2983920-B'), color: i18n.t('products.color.gray'), weightKg: 250 },
        { artNumber: '2983920-C', name: i18n.t('products.2983920-C'), color: i18n.t('products.color.gray'), weightKg: 800 },
      ],
    },
  ];
}
